// import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react'
// import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby';
import Layout from '../components/layouts'
import LayoutES from '../components/layouts-es';

import Banner from '../components/home/Banner';
import BannerES from '../components/home-es/Banner'

import Description from '../components/home/Description';
import DescriptionES from '../components/home-es/Description';

import Facts from '../components/home/Facts'
import FactsES from '../components/home-es/Facts';

import PricingES from '../components/home-es/Pricing';
import Pricing from '../components/home/Pricing'

import Testimonial from '../components/home/Testimonial'
import TestimonialES from '../components/home-es/Testimonial';

import FeatureCarousel from '../components/home/FeatureCarousel'
import TestCarouselES from '../components/home-es/FeatureCarousel'
// import BlogPosts from '../components/BlogPosts'
import store from '../store'
import { navigate } from "gatsby"

// Query for the Blog Home content in Prismic
export const query = graphql`
{
  bannerImages: allFile(filter: {relativeDirectory: {eq: "home/banner"}}) {
    edges {
      node {
        childImageSharp {
          fixed(width: 733) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
    }
  }
  descImages: allFile(filter: {relativeDirectory: {eq: "home/description"}}) {
    edges {
      node {
        childImageSharp {
          fixed(width: 790) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
    }
  }
  descImageFluid: allFile(filter: {relativeDirectory: {eq: "home/description"}}) {
    edges {
      node {
        childImageSharp {
          fluid(maxWidth: 790) {
            ...GatsbyImageSharpFluid
          }
        }
        name
      }
    }
  }
  testImages: allFile(filter: {relativeDirectory: {eq: "home/testimonials"}}) {
    edges {
      node {
        childImageSharp {
          fixed(height: 387) {
            ...GatsbyImageSharpFixed
          }
        }
        name
      }
    }
  }
}
`


export default ({ data }) => {
  const [preferredLanguage, setPreferredLanguage] = useState("eng")
  if(typeof localStorage !== 'undefined'){
    localStorage.setItem('preferredLanguage', 'eng');
  }
  store.dispatch({type: 'preferredLanguage', payload: 'eng'})
  /**
   * Whenever there is a change in the language selected from the Dropdown component, there is a dispatch of the current selected language
   * when that changes this callback function runs
   * and to stop listening, invoke the function returned by the subscribe
   */
  const unsubscribe = store.subscribe(() => {
    setPreferredLanguage(store.getState().preferredLanguage);
    if(preferredLanguage !== store.getState().preferredLanguage){
      unsubscribe();
    }
  })
  /**
   * whenever the component is dismounted then stop listening to the changes in the preferred language states
   */
  useEffect(() => {
    return () => { 
      unsubscribe() 
    }
  }, [])
  
  if(preferredLanguage == 'esp'){
    navigate('/es')
  }

  return(
    <Layout>
      <Banner bannerImages={data.bannerImages.edges}/>
      <Description descImages={data.descImages.edges} fluidImages={data.descImageFluid.edges}/>
      {/* <Features /> */}
      <FeatureCarousel/>
      <Facts />
      <Pricing />
      <Testimonial testImages={data.testImages.edges} />
    </Layout>
  ) 
}
