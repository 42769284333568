import React from 'react'
import { Link } from 'gatsby'
import ReactWOW from 'react-wow'
import Img from 'gatsby-image/withIEPolyfill'
const Description = ({descImages, fluidImages}) => {
    const tablet = descImages.filter(item => item.node.name === "mockup-tablet")[0];
    const phone = descImages.filter(item => item.node.name === "mockup-phone")[0];
    const laptop = descImages.filter(item => item.node.name === "mockup-laptop")[0];
    const all = fluidImages.filter(item => item.node.name === "mockup-responsive-no-shadow")[0];
    return(
        <section className="erp_features_area_two sec_pad">
            <div className="container">
                <div className="row erp_item_features align-items-center flex-row-reverse">
                    <div className="col-lg-6">
                        <div className="erp_features_img_two d-none d-xl-block">
                           
                            <ReactWOW animation="slideInRight" delay="0.9s">
                                <Img className="leaf app_tablet" fixed={tablet.node.childImageSharp.fixed} />
                            </ReactWOW>
                            <ReactWOW animation="slideInRight" delay="0.6s">
                                <Img className="leaf app_phone" fixed={phone.node.childImageSharp.fixed} />
                            </ReactWOW>
                            <ReactWOW animation="fadeInUp" delay="0.2s">
                                <Img className="app_laptop" fixed={laptop.node.childImageSharp.fixed} />
                                {/* <Img fluid={all.node.childImageSharp.fluid} /> */}
                            </ReactWOW>
                        </div>
                        <div className="erp_features_img_two d-block d-xl-none">
                            <ReactWOW animation="fadeInUp" delay="0.2s">
                                <Img fluid={all.node.childImageSharp.fluid} />
                            </ReactWOW>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="erp_content_two">
                            <div className="hosting_title erp_title">
                                <h2>The most comprehensive and affordable safety and compliance solution on the market.</h2>
                                <p>Choose from over 25 customizable tools that will not only help you score better on your next safety audit but also ensure that your health & safety program is effectively communicated to your workers.</p>
                            </div>
                            <div className="media erp_item">
                                <div className="icon blue">
                                    <img className="homeSVG" src="/app-icons/layout-icons/cell-phone.svg" alt="Cellphone"/>
                                </div>
                                <div className="media-body">
                                    <h3 style={{marginBottom: 0, fontSize: '18px', lineHeight: '22px', color: '#212322'}}>Mobile and Tablet App</h3>
                                </div>
                            </div>
                            <div className="media erp_item">
                                <div className="icon blue">
                                <img className="homeSVG" src="/app-icons/layout-icons/heart-line.svg" alt="Heart"/>
                                </div>
                                <div className="media-body">
                                    <h3 style={{marginBottom: 0, fontSize: '18px', lineHeight: '22px', color: '#212322'}}>User Friendly for Easy Worker Adoption</h3>
                                </div>
                            </div>
                            <Link to="/features/" className="erp_btn_learn">Browse All Workhub Features<i className="arrow_right"></i></Link>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Description