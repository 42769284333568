import React, { Component } from "react"
import Slider from "react-slick"
import { Link } from "gatsby"

export default class TestCarousel extends Component {
  render() {
    var settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 882,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 570,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <section className="feature_area_two">
        <div className="container custom_container">
          <div style={{ textAlign: "center", padding: "0.5rem" }}>
            <h2 className="f_p f_size_30 l_height50 f_600 mb-20">
              A complete system of features to optimize your workflow{" "}
            </h2>
            <p className="f_400 f_size_16 mb-0">
              Workhub has it all - from free online safety training courses to
              certificate tracking and policy distribution,{" "}
              <br className="header_break" /> forms, bulletins, competencies,
              incidents and{" "}
              <Link to="/features/" className="erp_btn_learn">
                many more features.
              </Link>
            </p>
          </div>
          {/* <LoadableFeatureCarousel /> */}

          <div className="carousel-container">
            <Slider {...settings}>
              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-training.svg"
                    alt="Training Icon"
                  />
                  <h3>Training Courses</h3>
                  <p>
                    Choose from over 60 included courses or create your own with
                    quiz.
                  </p>
                  <Link
                    to="/features/safety-training/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-policies.svg"
                    alt="Policies Icon"
                  />
                  <h3>Workplace Policies</h3>
                  <p>
                    Upload safety policies from your safety manual and ensure
                    acceptance.
                  </p>
                  <Link to="/features/policies/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-procedures.svg"
                    alt="Procedures Icon"
                  />
                  <h3>Operating Procedures</h3>
                  <p>
                    Host your safety procedures and track which workers have
                    reviewed them.
                  </p>
                  <Link
                    to="/features/standard-operating-procedures/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-certificates.svg"
                    alt="Certificate Tracking Icon"
                  />
                  <h3>Certificate Tracking</h3>
                  <p>
                    Track any third party certifications for a worker from
                    professional designations.
                  </p>
                  <Link
                    to="/features/certificate-tracking/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-polls.svg"
                    alt="Polls Icon"
                  />
                  <h3>Polls</h3>
                  <p>
                    Make decisions a democratic endeavor by polling your workers
                    for their preferences.{" "}
                  </p>
                  <Link to="/features/polls/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-suggestions.svg"
                    alt="Suggestions Icon"
                  />
                  <h3>Suggestions</h3>
                  <p>
                    Allow workers to provide you with ideas that can make your
                    workplace better.{" "}
                  </p>
                  <Link to="/features/suggestions/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-inspections.svg"
                    alt="Inspections Icon"
                  />
                  <h3>Inspections</h3>
                  <p>
                    Schedule inspections for asset categories, location and
                    more.{" "}
                  </p>
                  <Link to="/features/inspections/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-incidents.svg"
                    alt="Incidents Reporting Icon"
                  />
                  <h3>Incident Reporting</h3>
                  <p>
                    Track incidents and gather all your incident documents in
                    one place.{" "}
                  </p>
                  <Link
                    to="/features/incident-reporting/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-competencies.svg"
                    alt="Competencies Icon"
                  />
                  <h3>Competencies</h3>
                  <p>
                    Determine worker competency by attaching job tasks and allow
                    for supervisor review.
                  </p>
                  <Link to="/features/competencies/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-bulletins.svg"
                    alt="Bulletins Icon"
                  />
                  <h3>Bulletins & Alerts</h3>
                  <p>
                    Keep workers up to date via email and SMS with read receipts
                    to track readership.
                  </p>
                  <Link to="/features/bulletins/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-whistleblower.svg"
                    alt="Whistleblower Icon"
                  />
                  <h3>Whistleblower</h3>
                  <p>Allow anonymous submission of alleged policy breaches.</p>
                  <Link to="/features/whistleblower/" className="erp_btn_learn">
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-orientations.svg"
                    alt="Orientations"
                  />
                  <h3>Contractor Orientations</h3>
                  <p>
                    Host your orientation with convenient sub-contract worker
                    access to them.
                  </p>
                  <Link
                    to="/features/contractor-orientations/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-surveys.svg"
                    alt="Surveys Icon"
                  />
                  <h3>Surveys</h3>
                  <p>
                  Find out what your workers think of your safety program, culture or strategy.
                  </p>
                  <Link
                    to="/features/surveys/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>

              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-safety-meetings.svg"
                    alt="Safety Meetings Icon"
                  />
                  <h3>Safety Meetings</h3>
                  <p>
                  Review unsafe habits or behaviors, answer questions & take corrective action.
                  </p>
                  <Link
                    to="/features/safety-meetings/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>


              <div className="carousel_contain">
                <div className="carousel_item">
                  <img
                    className="illustration"
                    src="/app-icons/small/workhub-icon-behaviour-observation.svg"
                    alt="Behaviour Observations"
                  />
                  <h3>Behavior Observations</h3>
                  <p>
                    Assess your workers' behaviors to identify and assess any
                    unsafe working habits
                  </p>
                  <Link
                    to="/features/behavior-observations/"
                    className="erp_btn_learn"
                  >
                    Learn More{" "}
                    <img
                      style={{ display: "inline", paddingLeft: ".5rem" }}
                      src="/app-icons/layout-icons/long-arrow-right.svg"
                      alt="Arrow"
                    />
                  </Link>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    )
  }
}
