import React from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import ReactWOW from 'react-wow';
const Facts = () => {

    return (
        <section className="app_fact_area sec_pad">

            <div className="container">
                <ReactWOW animation="fadeInUp" delay="0.3s">
                    <div className="fact_title text-center mb_70">
                        <h2>Helping over 20,000+ organizations<br /> achieve complete compliance</h2>
                    </div>
                </ReactWOW>
                <div className="app_fact_info">
                    <div className="app_fact_item">
                        <div className="text">
                            <CountUp
                                start={0}
                                end={359868}
                                duration={1}
                                separator=","
                                delay={0}>
                                {({ countUpRef, start}) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span className="counter one" ref={countUpRef} />    
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <p>Users</p>
                        </div>
                    </div>
                    <div className="app_fact_item">
                        <div className="text">
                            <CountUp
                                start={0}
                                end={21045}
                                duration={2}
                                separator=","
                                delay={0}>
                                {({ countUpRef, start}) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span className="counter two" ref={countUpRef} />    
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <p>Happy Companies</p>
                        </div>
                    </div>
                    <div className="app_fact_item">
                        <div className="text">
                            <CountUp
                                start={0}
                                end={11379947}
                                duration={2}
                                separator=","
                                delay={0}>
                                {({ countUpRef, start}) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span className="counter three" ref={countUpRef} />    
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <p>Quizzes Taken</p>
                        </div>
                    </div>
                    <div className="app_fact_item last">
                        <div className="text">
                            <CountUp
                                start={0}
                                end={2416366}
                                duration={2}
                                separator=","
                                delay={0}>
                                {({ countUpRef, start}) => (
                                    <VisibilitySensor onChange={start} delayedCall>
                                        <span className="counter four" ref={countUpRef} />    
                                    </VisibilitySensor>
                                )}
                            </CountUp>
                            <p>Documents Tracked</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Facts