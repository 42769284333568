import React from 'react'
import Typed from 'react-typed';

 
const ComponentsTyped = () => {
    return (
        <Typed
        strings={[
            'Policies',
            'Procedures',
            'Training',
            'Certificates',
            'Bulletins',
            'Incidents',
            'Competencies',
            'Safety Audits',
            'Inspections',
            'Forms & Records',
            'Orientations']}
            typeSpeed={80}
            startDelay={1000}
            backDelay={1000}
            loop >
        </Typed>
    );
}
 
export default ComponentsTyped