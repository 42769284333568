import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import ReactWOW from 'react-wow'
const Testimonial = ({testImages}) => {
    const testimonial = testImages.filter(item => item.node.name === "testimonial-default-img")[0];
    return (
        <section className="payment_testimonial_area">
            <div className="container">
                <div className="row payment_testimonial_info flex-row-reverse">
                    <div className="col-lg-7 d-flex align-items-center">
                        <ReactWOW animation="fadeInRight" delay="0.2s">
                            <div className="testimonial_content">
                                <div className="icon">,,</div>
                                <p className="f_p f_size_20">
                                    This is a very user friendly software to set up as well as roll out to a large group of workers who have various levels of computer skills. It is easy to track training including certificates which I find makes my role easier. Keeping track of 500 employees individual safety training is a full time job and Workhub has simplified this process. It truly is <b><i>the iPhone of safety software- user friendly, streamlined and you wonder how you ever worked without it!</i></b>
                                    </p>
                                <div className="author f_600 f_p t_color f_size_20">Kelsea McLaughlin</div>
                                <div className="author_description f_p f_size_15">Safety Administrator / Viking Air</div>
                            </div>
                        </ReactWOW>
                    </div>
                    <div className="col-lg-5">
                        <div className="testimonial_img">
                            <ReactWOW animation="fadeInLeft" delay="0.4s">
                                <Img fixed={testimonial.node.childImageSharp.fixed} />
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonial