import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import ComponentsTyped from './ComponentsTyped'
import Img from 'gatsby-image/withIEPolyfill'
import Plx from 'react-plx'
import ReactWOW from 'react-wow'
import queryString from 'query-string'
import { isWindows } from 'react-device-detect'

function Banner({ bannerImages }) {
    // const breakpoint = `${<br/>}`

    const [title, setTitle] = useState('Compliance<br/>Management Software')

    // Function strips "-" leave just in case

     useEffect(() => {
        const values = queryString.parse(window.location.search)

        let stripTitle = (values.k != undefined) ? values.k.replace(/-/g, ' ') : undefined;

        let breakTitle = (stripTitle != undefined) ? stripTitle.replace(/4/g, '<br/>') : title;

        // console.log(breakTitle)

        setTitle(breakTitle)
      });

    // useEffect(() => {
    //     const values = queryString.parse(window.location.search)

    //     let stripTitle = (values.k != undefined) ? values.k.split('-') : undefined;

    //     let titleArray = (stripTitle != undefined) ? stripTitle.slice(0, 1) : undefined;

    //     let arrToString = (titleArray != undefined) ? titleArray.toString() : title;

    //     // console.log(arrToString)

    //     setTitle(arrToString)
    // });


    const parallaxLaptop = bannerImages.filter(item => item.node.name === "sketch-laptop-cert")[0]
    const laptopSettings = [
        {
            start: 0,
            end: 1000,
            properties: [
                {
                    startValue: 0,
                    endValue: -50,
                    property: 'translateX',
                },
                {
                    startValue: 0,
                    endValue: 270,
                    property: 'translateY'
                }
            ]
        },
    ]

    const parallaxSwan = bannerImages.filter(item => item.node.name === "icon-swan")[0]
    const swanSettings = [
        {
            start: 0,
            end: 1000,
            properties: [
                {
                    startValue: 0,
                    endValue: -50,
                    property: 'translateX',
                },
                {
                    startValue: 0,
                    endValue: 300,
                    property: 'translateY'
                },
                {
                    startValue: 0,
                    endValue: 1500,
                    property: 'rotateZ'
                },
            ]
        },
    ]

    const parallaxSurvey = bannerImages.filter(item => item.node.name === "icon-surveys")[0]
    const surveySettings = [
        {
            start: 0,
            end: 1000,
            properties: [
                {
                    startValue: 0,
                    endValue: 50,
                    property: 'translateX',
                },
                {
                    startValue: 0,
                    endValue: 400,
                    property: 'translateY'
                },
                {
                    startValue: 0,
                    endValue: -500,
                    property: 'rotateY'
                },
            ]
        },
    ]

    const parallaxBulletin = bannerImages.filter(item => item.node.name === "icon-bulletins")[0]
    const bulletinSettings = [
        {
            start: 0,
            end: 1000,
            properties: [
                {
                    startValue: 0,
                    endValue: 20,
                    property: 'translateX',
                },
                {
                    startValue: 0,
                    endValue: -100,
                    property: 'translateY'
                },
                {
                    startValue: 0,
                    endValue: -800,
                    property: 'rotateX'
                },
            ]
        },
    ]

    const parallaxMocOne = bannerImages.filter(item => item.node.name === "dash-mockup")[0]
    const mocOneSettings = [
        {
            start: 0,
            end: 1000,
            properties: [
                {
                    startValue: 0,
                    endValue: 30,
                    property: 'translateY'
                },
            ]
        }
    ]

    const parallaxMocTwo = bannerImages.filter(item => item.node.name === "competencies")[0]
    const mocTwoSettings = [
        {
            start: 0,
            end: 1000,
            properties: [
                {
                    startValue: 0,
                    endValue: 40,
                    property: 'translateY'
                },
            ]
        },
    ]

    const parallaxMocThr = bannerImages.filter(item => item.node.name === "competencies")[0]
    const mocThrSettings = [
        {
            start: 0,
            end: 1000,
            properties: [
                {
                    startValue: 0,
                    endValue: 90,
                    property: 'translateY'
                },
            ]
        },
    ]

    const parallaxMocFou = bannerImages.filter(item => item.node.name === "policies")[0]
    const mocFouSettings = [
        {
            start: 0,
            end: 1000,
            properties: [
                {
                    startValue: 0,
                    endValue: 80,
                    property: 'translateY'
                },
            ]
        },
    ]

    const parallaxMocFiv = bannerImages.filter(item => item.node.name === "competencies")[0]
    const mocFivSettings = [
        {
            start: 0,
            end: 1000,
            properties: [
                {
                    startValue: 0,
                    endValue: -40,
                    property: 'translateY'
                },
            ]
        },
    ]

    const parallaxMocSix = bannerImages.filter(item => item.node.name === "certificates")[0]
    const mocSixSettings = [
        {
            start: 0,
            end: 1000,
            properties: [
                {
                    startValue: 0,
                    endValue: 80,
                    property: 'translateY'
                },
            ]
        },
    ]

    const parallaxMocSev = bannerImages.filter(item => item.node.name === "training")[0]
    const mocSevSettings = [
        {
            start: 0,
            end: 1000,
            properties: [
                {
                    startValue: 0,
                    endValue: 50,
                    property: 'translateY'
                },
            ]
        },
    ]
    return (
        <section className="banner_area">


            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="banner_content">
                            <h1 className="banner_title text-center">All-in-one  <span style={{textTransform: 'capitalize'}} dangerouslySetInnerHTML={{__html: title}} />
                            
                            {/* <span style={{ textTransform: 'capitalize' }}>{title}</span>  */}
                           
                            </h1>
                            <h2 className=" text-center" style={{ fontSize: '1.75rem' }}>Workhub allows you to easily manage your <br /> <mark className='banner-mark'> <ComponentsTyped /></mark> all in one simple hub.</h2>

                            <p className="banner_para text-center"> Simplify worker safety management, while building a workplace<br /> culture that lasts. Setup is a breeze!</p>


                            <div className="banner_btn text-center">
                                <Link to="/signup/">Sign Up For Free</Link>
                                <i>No credit card needed</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mockup_image">
                <Plx className="mok_img mok_img_1" parallaxData={mocOneSettings}>
                    <ReactWOW animation="fadeInUp" delay="900ms">
                        <Img fixed={parallaxMocOne.node.childImageSharp.fixed} />
                    </ReactWOW>
                </Plx>
                <Plx className="mok_img mok_img_2" parallaxData={mocTwoSettings}>
                    <ReactWOW className="layer" animation="fadeInUp" delay="1100ms">
                        <Img fixed={parallaxMocTwo.node.childImageSharp.fixed} />
                    </ReactWOW>
                </Plx>
                <Plx className="mok_img mok_img_3" parallaxData={mocThrSettings}>
                    <ReactWOW className="layer" animation="fadeInUp" delay="900ms">
                        <Img fixed={parallaxMocThr.node.childImageSharp.fixed} />
                    </ReactWOW>
                </Plx>
                <Plx className="mok_img mok_img_4" parallaxData={mocFouSettings}>
                    <ReactWOW className="layer" animation="fadeInUp" delay="200ms">
                        <Img fixed={parallaxMocFou.node.childImageSharp.fixed} />
                    </ReactWOW>
                </Plx>
                <Plx className="mok_img mok_img_5" parallaxData={mocFivSettings}>
                    <ReactWOW className="layer" animation="fadeInUp" delay="1200ms">
                        <Img fixed={parallaxMocFiv.node.childImageSharp.fixed} />
                    </ReactWOW>
                </Plx>
                <Plx className="mok_img mok_img_6" parallaxData={mocSixSettings}>
                    <ReactWOW className="layer" animation="fadeInUp" delay="500ms">
                        <Img fixed={parallaxMocSix.node.childImageSharp.fixed} />
                    </ReactWOW>
                </Plx>
                <Plx className="mok_img mok_img_7" parallaxData={mocSevSettings}>
                    <ReactWOW className="layer" animation="fadeInUp" delay="700ms">
                        <Img fixed={parallaxMocSev.node.childImageSharp.fixed} />
                    </ReactWOW>
                </Plx>
            </div>
        </section>
    )
}

export default Banner

